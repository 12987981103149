const Boxes = [
  { label: "Total no. of Guest", value: 3598 },
  { label: "Today Check-In", value: 200 },
  { label: "Today Check-Out", value: 35 },
  { label: "Booked this month", value: 148 },
  { label: "Cancelled this month", value: 23 },
  { label: "Rooms in use", value: "125 / 130" },
];

export default Boxes;
