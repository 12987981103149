const RoomNumbers = [
  {
    floor: 1,
    rooms: [
      { no: 101, color: "#C9FED0" },
      { no: 102, color: "#C9FED0" },
      { no: 103, color: "#C9FED0" },
      { no: 104, color: "#C9FED0" },
      { no: 105, color: "#C9FED0" },
      { no: 106, color: "#C9FED0" },
      { no: 107, color: "#C9FED0" },
      { no: 108, color: "#C9FED0" },
      { no: 109, color: "#C9FED0" },
      { no: 110, color: "#C9FED0" },
    ],
  },
  {
    floor: 2,
    rooms: [
      { no: 201, color: "#C9FED0" },
      { no: 202, color: "#FFB4B0" },
      { no: 203, color: "#C9FED0" },
      { no: 204, color: "#C9FED0" },
      { no: 205, color: "#C9FED0" },
      { no: 206, color: "#C9FED0" },
      { no: 207, color: "#C9FED0" },
      { no: 208, color: "#C9FED0" },
      { no: 209, color: "#C9FED0" },
      { no: 210, color: "#C9FED0" },
    ],
  },
  {
    floor: 3,
    rooms: [
      { no: 301, color: "#C9FED0" },
      { no: 302, color: "#C9FED0" },
      { no: 303, color: "#F6D7FF" },
      { no: 304, color: "#C9FED0" },
      { no: 305, color: "#C9FED0" },
      { no: 306, color: "#C9FED0" },
      { no: 307, color: "#C9FED0" },
      { no: 308, color: "#C9FED0" },
      { no: 309, color: "#C9FED0" },
      { no: 310, color: "#C9FED0" },
    ],
  },
  {
    floor: 4,
    rooms: [
      { no: 401, color: "#C9FED0" },
      { no: 402, color: "#C9FED0" },
      { no: 403, color: "#C9FED0" },
      { no: 404, color: "#C9FED0" },
      { no: 405, color: "#FFB4B0" },
      { no: 406, color: "#C9FED0" },
      { no: 407, color: "#C9FED0" },
      { no: 408, color: "#C9FED0" },
      { no: 409, color: "#C9FED0" },
      { no: 410, color: "#C9FED0" },
    ],
  },
  {
    floor: 5,
    rooms: [
      { no: 501, color: "#C9FED0" },
      { no: 502, color: "#C9FED0" },
      { no: 503, color: "#C9FED0" },
      { no: 504, color: "#C9FED0" },
      { no: 505, color: "#FFFBA8" },
      { no: 506, color: "#C9FED0" },
      { no: 507, color: "#FFFBA8" },
      { no: 508, color: "#C9FED0" },
      { no: 509, color: "#C9FED0" },
      { no: 510, color: "#C9FED0" },
    ],
  },
  {
    floor: 6,
    rooms: [
      { no: 601, color: "#C9FED0" },
      { no: 602, color: "#C9FED0" },
      { no: 603, color: "#C9FED0" },
      { no: 604, color: "#C9FED0" },
      { no: 605, color: "#C9FED0" },
      { no: 606, color: "#C9FED0" },
      { no: 607, color: "#C9FED0" },
      { no: 608, color: "#C9FED0" },
      { no: 609, color: "#C9FED0" },
      { no: 610, color: "#C9FED0" },
    ],
  },
  {
    floor: 7,
    rooms: [
      { no: 701, color: "#C9FED0" },
      { no: 702, color: "#C9FED0" },
      { no: 703, color: "#FFFBA8" },
      { no: 704, color: "#C9FED0" },
      { no: 705, color: "#C9FED0" },
      { no: 706, color: "#C9FED0" },
      { no: 707, color: "#C9FED0" },
      { no: 708, color: "#C9FED0" },
      { no: 709, color: "#C9FED0" },
      { no: 710, color: "#C9FED0" },
    ],
  },
  {
    floor: 8,
    rooms: [
      { no: 801, color: "#C9FED0" },
      { no: 802, color: "#C9FED0" },
      { no: 803, color: "#C9FED0" },
      { no: 804, color: "#C9FED0" },
      { no: 805, color: "#C9FED0" },
      { no: 806, color: "#C9FED0" },
      { no: 807, color: "#C9FED0" },
      { no: 808, color: "#C9FED0" },
      { no: 809, color: "#C9FED0" },
      { no: 810, color: "#C9FED0" },
    ],
  },
  {
    floor: 9,
    rooms: [
      { no: 901, color: "#C9FED0" },
      { no: 902, color: "#C9FED0" },
      { no: 903, color: "#C9FED0" },
      { no: 904, color: "#C9FED0" },
      { no: 905, color: "#C9FED0" },
      { no: 906, color: "#C9FED0" },
      { no: 907, color: "#C9FED0" },
      { no: 908, color: "#C9FED0" },
      { no: 909, color: "#C9FED0" },
      { no: 910, color: "#C9FED0" },
    ],
  },
  {
    floor: 10,
    rooms: [
      { no: 1001, color: "#C9FED0" },
      { no: 1002, color: "#C9FED0" },
      { no: 1003, color: "#C9FED0" },
      { no: 1004, color: "#C9FED0" },
      { no: 1005, color: "#C9FED0" },
      { no: 1006, color: "#C9FED0" },
      { no: 1007, color: "#C9FED0" },
      { no: 1008, color: "#C9FED0" },
      { no: 1009, color: "#C9FED0" },
      { no: 1010, color: "#C9FED0" },
    ],
  },
  {
    floor: 11,
    rooms: [
      { no: 1101, color: "#C9FED0" },
      { no: 1102, color: "#C9FED0" },
      { no: 1103, color: "#C9FED0" },
      { no: 1104, color: "#C9FED0" },
      { no: 1105, color: "#C9FED0" },
      { no: 1106, color: "#C9FED0" },
      { no: 1107, color: "#C9FED0" },
      { no: 1108, color: "#C9FED0" },
      { no: 1109, color: "#C9FED0" },
      { no: 1110, color: "#C9FED0" },
    ],
  },
  {
    floor: 12,
    rooms: [
      { no: 1201, color: "#C9FED0" },
      { no: 1202, color: "#C9FED0" },
      { no: 1203, color: "#C9FED0" },
      { no: 1204, color: "#C9FED0" },
      { no: 1205, color: "#C9FED0" },
      { no: 1206, color: "#C9FED0" },
      { no: 1207, color: "#C9FED0" },
      { no: 1208, color: "#C9FED0" },
      { no: 1209, color: "#C9FED0" },
      { no: 1210, color: "#C9FED0" },
    ],
  },
];

export default RoomNumbers;
