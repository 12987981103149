import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage">
      <h2>404</h2>
      <p>Sorry, Page not found</p>
    </div>
  );
};

export default NotFoundPage;
