const Logo = {
  image: `<svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 65 70" fill="none">
    <g clip-path="url(#clip0_1107_2791)">
      <path d="M4.43319 0H60.516C62.9466 0 64.9588 1.98343 64.9588 4.44501V65.5661C64.9512 66.7412 64.4804 67.866 63.6486 68.6961C62.8168 69.5262 61.6911 69.9948 60.516 70H4.43319C3.25941 69.9936 2.13557 69.5243 1.30578 68.6941C0.475988 67.8639 0.00727846 66.7398 0.00146484 65.5661L0.00146484 4.44501C0.00146484 1.98343 2.00482 0 4.43319 0Z" fill="#A5333A"/>
      <path d="M4.43299 1.01611H60.5158C62.3996 1.01611 63.9226 2.56124 63.9226 4.44284V65.5639C63.9226 67.4278 62.3996 68.9707 60.5158 68.9707H4.43299C3.52935 68.9684 2.66325 68.609 2.02345 67.9708C1.38365 67.3327 1.022 66.4675 1.01733 65.5639V4.44506C1.01791 3.53812 1.37754 2.6683 2.01759 2.02575C2.65765 1.38321 3.52607 1.02021 4.43299 1.01611Z" fill="#E9CD3A"/>
      <path d="M4.43308 1.64478H60.5159C62.0389 1.64478 63.3051 2.88885 63.3051 4.44504V65.5661C63.3051 67.1002 62.0411 68.3464 60.5159 68.3464H4.43308C3.69419 68.3453 2.98561 68.0526 2.46128 67.532C1.93694 67.0114 1.63923 66.305 1.63281 65.5661V4.44504C1.63573 3.70326 1.93169 2.9927 2.45621 2.46817C2.98073 1.94365 3.6913 1.64769 4.43308 1.64478Z" fill="#A5333A"/>
      <path d="M44.9363 25.2421C44.1688 27.9734 42.5307 30.3795 40.2708 32.0948C38.011 33.8101 35.253 34.7407 32.4159 34.7453C25.2724 34.7453 19.4373 28.919 19.4373 21.7556C19.4373 21.1181 19.4771 20.4806 19.5745 19.8652C23.351 18.7584 29.0467 17.563 29.0467 17.563C26.0295 19.3583 22.5231 21.718 22.5231 24.9765C22.5231 27.8941 26.8375 28.6821 30.3439 28.3235C32.9139 28.0535 41.2506 26.121 44.9363 25.2421Z" fill="#E9CD3A"/>
      <path d="M32.4158 8.76611C35.3944 8.77099 38.2813 9.79735 40.5946 11.6739C42.9079 13.5504 44.5075 16.1636 45.1265 19.0773C35.7717 22.2849 27.6631 24.1576 27.6631 21.7979C27.6631 19.427 37.9145 16.5072 37.9145 16.5072L38.0716 14.1475L19.8157 18.6191C21.228 12.9698 26.3504 8.76611 32.4158 8.76611Z" fill="#E9CD3A"/>
      <path d="M14.4255 46.6703L14.9435 46.7588L14.4255 49.6078C14.4255 49.6078 12.3136 50.0262 10.8084 50.0262C9.32522 50.0262 6.48511 49.6985 6.48511 46.0195C6.48511 42.3337 7.9107 39.6862 13.0796 39.6862C13.0796 39.6862 15.3486 39.7261 16.1588 39.8057L15.4902 43.0023H14.9435C14.9435 43.0023 15.1316 40.1931 13.0508 40.1931C10.68 40.1931 9.2256 43.4295 9.2256 46.5884C9.2256 46.5884 8.97546 49.3886 11.0784 49.3886C13.219 49.3908 14.0978 47.8059 14.4255 46.6703ZM19.6054 48.9016L19.5257 49.4395C19.5257 49.4395 17.5423 49.7295 16.8361 49.7295C16.1101 49.7295 15.7094 49.3112 15.9684 47.9166L17.5733 39.9076H16.3381V39.301C16.3381 39.301 19.3553 39.27 20.223 39.1018L18.5384 47.4982C18.5406 47.496 18.1223 49.0411 19.6054 48.9016ZM48.5843 61.8006L48.4847 62.3385C48.4847 62.3385 46.4813 62.6395 45.7442 62.6395C45.0358 62.6395 44.6285 62.2101 44.8786 60.8354L46.5012 52.8353H45.2638V52.2199C45.2638 52.2199 48.3341 52.1889 49.1886 52.0184L47.4752 60.386C47.4775 60.386 47.0591 61.94 48.5843 61.8006Z" fill="white"/>
      <path d="M27.9044 48.8219V49.2713C27.9044 49.2713 26.1512 49.869 25.1351 49.869C24.1191 49.869 24.1988 49.0721 24.2585 48.6249C24.2585 48.6249 23.2226 49.9309 21.3985 49.9309C19.5855 49.9309 19.5745 48.4257 19.5745 48.0294C19.5745 47.6398 19.6453 45.559 22.6027 45.4904C22.9724 45.4682 24.7743 45.1517 24.9448 44.2352C25.1551 43.0399 24.6858 42.8606 24.1766 42.8406C23.6188 42.8207 23.1805 43.0509 22.9237 43.9452C22.6536 44.8218 22.0272 44.9325 21.6487 44.9325C21.2591 44.9325 20.772 44.6115 20.772 43.9452C20.772 43.2878 21.29 42.3713 24.2785 42.3713C27.258 42.3713 27.7539 43.0399 27.4263 44.7931L26.9193 47.3941C26.9171 47.3875 26.5187 48.9614 27.9044 48.8219ZM30.7512 49.5281L28.3029 49.5591L29.5779 43.3542H28.5198L28.5995 42.7167C28.5995 42.7167 31.3378 42.7167 32.0661 42.5462L31.9443 43.4029C31.9443 43.4029 32.7811 42.4776 33.9875 42.4776C35.2028 42.4776 35.3511 43.4937 35.3511 43.8833C35.3511 44.2706 35.0833 45.2469 34.1358 45.2469C33.2194 45.2469 33.2504 44.6093 33.2504 44.3703C33.2504 44.14 33.2017 43.5733 32.6925 43.5733C32.1746 43.5733 31.796 43.963 31.5769 45.1782L30.7512 49.5281Z" fill="white"/>
      <path d="M35.3533 43.2636L35.4529 42.6261C35.4529 42.6261 37.8657 42.6372 38.9017 42.4468L37.837 47.775C37.837 47.775 37.4274 48.9815 38.8619 48.842L38.8132 49.4397C38.8132 49.4397 36.6018 49.6788 36.0439 49.6788C35.4661 49.6788 35.0987 49.3578 35.3555 48.065L36.2719 43.2835C36.2697 43.2835 35.7119 43.2946 35.3533 43.2636Z" fill="white"/>
      <path d="M42.3771 49.7186C39.9067 49.6987 38.78 48.6539 38.9017 47.0977C39.1607 44.0185 40.086 42.376 44.3097 42.3273C46.9306 42.2963 47.7674 43.5713 47.5859 45.0058C47.2472 47.6378 46.8996 49.7297 42.3771 49.7186ZM27.6652 62.529C25.1749 62.529 24.0282 61.4643 24.1699 59.9191C24.4267 56.8488 25.3652 55.2262 29.6575 55.1576C32.2873 55.1465 33.1329 56.4127 32.9426 57.8272C32.6149 60.477 32.2763 62.56 27.6652 62.529ZM38.1933 41.2005C37.4252 41.2005 36.9559 40.6427 36.967 40.0051C36.9869 39.1795 37.5846 38.5508 38.49 38.5508C39.247 38.5508 39.765 38.9404 39.7429 39.6886C39.7418 39.89 39.7006 40.0892 39.6219 40.2746C39.5431 40.4599 39.4284 40.6278 39.2842 40.7685C39.14 40.9092 38.9694 41.0198 38.7821 41.0939C38.5948 41.1681 38.3947 41.2043 38.1933 41.2005ZM50.0164 49.4087C50.0164 49.4087 48.0264 49.4995 47.4862 49.5283L48.8698 43.2836H47.8161V42.6062C47.8161 42.6062 50.6053 42.5265 51.4022 42.407L51.2229 43.3743C51.2229 43.3743 52.7459 42.2476 54.3198 42.2476C55.9136 42.2476 56.2833 43.0334 56.0132 44.5896L55.4244 47.3899C55.4244 47.3899 55.0082 48.8841 56.5002 48.7159L56.56 49.194C56.56 49.194 54.8068 49.5814 53.6911 49.5814C52.5643 49.5814 52.7835 48.4746 52.9052 47.857L53.5428 44.5298C53.5428 44.5298 53.7619 43.3455 52.6064 43.3455C51.4708 43.3455 50.9749 44.3926 50.8244 45.2869L50.0164 49.4087Z" fill="white"/>
      <path d="M14.6646 53.0656H15.8999L13.9673 61.7121H12.8406L12.6724 62.3607H17.8213L17.963 61.7121H16.7477L17.6929 57.4575H21.1396L20.1744 61.7121H19.1672L18.999 62.3607H24.0284L24.1767 61.7121H22.9525L24.8851 53.0656H25.9609L26.1092 52.4192H21.1905L21.0311 53.0656H22.1069L21.299 56.6716H17.8634L18.6581 53.0656H19.7737L19.9442 52.4192H14.8152L14.6646 53.0656ZM37.1775 61.8604L36.9472 62.478C36.9472 62.478 34.8243 62.7259 33.8083 62.7259C32.8033 62.7259 33.1309 61.4907 33.2814 60.924L34.2975 56.2222H33.0623L33.2217 55.5958H34.437L34.7159 54.3296C34.7159 54.3296 36.2499 54.0706 37.0579 52.8354C37.0579 52.8354 37.6645 52.5764 37.5161 53.0257L36.9494 55.5958H38.1448L37.9943 56.2222H36.7989L35.9024 60.1559C35.9024 60.1559 35.6235 61.2915 35.8714 61.5704C36.1326 61.8604 36.5399 61.8515 37.1775 61.8604Z" fill="white"/>
      <path d="M44.0995 59.9986L44.5489 60.479C44.5489 60.479 43.462 62.7192 40.2456 62.7192C37.0269 62.7192 37.1066 60.479 37.1066 59.9411C37.1066 59.3722 37.1376 55.3389 42.6252 55.3389C42.6252 55.3389 44.7371 55.091 44.7371 56.7224C44.7371 58.5664 43.3535 58.956 40.0552 59.4629C40.0552 59.4629 39.6457 61.9732 41.5096 61.9732C43.3624 61.971 44.0995 59.9986 44.0995 59.9986Z" fill="white"/>
      <path d="M24.7766 45.6343C24.7766 45.6343 24.4379 48.7135 23.123 48.7135C21.797 48.7135 22.2376 47.1484 22.6736 46.5596C23.123 45.9641 24.1479 45.5945 24.7766 45.6343ZM42.5876 49.1009C41.7708 49.1319 41.4919 48.284 41.5295 47.6576C41.7885 44.9304 42.5766 42.9868 43.9402 42.9248C44.4781 42.9248 44.9872 43.2945 44.9275 44.3305C44.8566 45.5745 44.3608 49.0411 42.5876 49.1009ZM27.9133 61.94C27.0456 61.96 26.7667 61.0834 26.8375 60.5056C27.0965 57.7673 27.8824 55.8038 29.277 55.764C29.8149 55.744 30.324 56.1336 30.2642 57.1497C30.2155 58.4159 29.6975 61.8604 27.9133 61.94ZM40.177 58.9826C40.177 58.9826 40.4072 55.8857 41.8704 55.8857C43.1344 55.8857 42.5079 57.7983 41.8394 58.2875C41.3313 58.6168 40.7682 58.8522 40.177 58.9826Z" fill="#A5333A"/>
    </g>
    <defs>
      <clipPath id="clip0_1107_2791">
        <rect width="64.96" height="70" fill="white"/>
      </clipPath>
    </defs>
  </svg>`,
  logout: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M3 4.92857C3 4.41708 3.20319 3.92654 3.56487 3.56487C3.92654 3.20319 4.41708 3 4.92857 3H13.9286C14.4401 3 14.9306 3.20319 15.2923 3.56487C15.654 3.92654 15.8571 4.41708 15.8571 4.92857V7.42157C15.3355 7.83942 14.997 8.44399 14.9134 9.10714H10.3929C10.013 9.10714 9.63679 9.18197 9.28581 9.32735C8.93483 9.47273 8.61592 9.68581 8.3473 9.95444C8.07867 10.2231 7.86559 10.542 7.72021 10.893C7.57483 11.2439 7.5 11.6201 7.5 12C7.5 12.3799 7.57483 12.7561 7.72021 13.107C7.86559 13.458 8.07867 13.7769 8.3473 14.0456C8.61592 14.3142 8.93483 14.5273 9.28581 14.6727C9.63679 14.818 10.013 14.8929 10.3929 14.8929H14.9134C14.997 15.556 15.3355 16.1606 15.8571 16.5784V19.0714C15.8571 19.5829 15.654 20.0735 15.2923 20.4351C14.9306 20.7968 14.4401 21 13.9286 21H4.92857C4.41708 21 3.92654 20.7968 3.56487 20.4351C3.20319 20.0735 3 19.5829 3 19.0714V4.92857ZM17.0953 8.53757C16.9191 8.61056 16.7684 8.73417 16.6625 8.89277C16.5565 9.05137 16.5 9.23783 16.5 9.42857V10.7143H10.3929C10.0519 10.7143 9.72484 10.8497 9.48372 11.0909C9.2426 11.332 9.10714 11.659 9.10714 12C9.10714 12.341 9.2426 12.668 9.48372 12.9091C9.72484 13.1503 10.0519 13.2857 10.3929 13.2857H16.5V14.5714C16.5002 14.762 16.5568 14.9483 16.6628 15.1067C16.7688 15.2651 16.9193 15.3886 17.0954 15.4615C17.2715 15.5344 17.4652 15.5535 17.6522 15.5164C17.8391 15.4792 18.0109 15.3875 18.1457 15.2529L20.7171 12.6814C20.8977 12.5006 20.9992 12.2555 20.9992 12C20.9992 11.7445 20.8977 11.4994 20.7171 11.3186L18.1457 8.74714C18.011 8.61228 17.8392 8.5204 17.6523 8.48309C17.4653 8.44579 17.2715 8.46475 17.0953 8.53757Z" fill="#4D4D4D"/>
</svg>`,
};

export default Logo;
